
<template>
  <BackofficeBase :loader_prop="loader_prop">
    <div id="nav">
      <router-link
        :to="{
          path: '/backoffice/point_details',
          query: { id: view_data.user_details[0].distribution_point_id },
        }"
        >פרטים</router-link
      >
      | <router-link to="/backoffice/backoffice_users">סטטיסטיקה</router-link> |
      <router-link to="/backoffice/file_uploader">ניהול נק' חלוקה</router-link>
    </div>
    <h3>{{ view_data.user_details[0].name }}</h3>
    <h3>{{ view_data.user_details[0].phone_number }} :טלפון</h3>
    <h3>{{ view_data.user_details[0].email }} :מייל</h3>

    <div
      v-for="user_package in view_data.user_packages"
      :key="user_package.id"
      class="item_list"
    >
      <p>{{ user_package.package_number }} :מספר חבילה</p>
      <p>{{ user_package.box_id }} :simpo מספר תיבת</p>
      <p>
        {{ user_package.ts_time }} {{ user_package.ts_date }} :תאריך קבלת חבילה
      </p>
      <div v-if="user_package.status == 'collected'">
        <p>{{ user_package.delivery_time }} :תאריך הוצאת חבילה</p>
      </div>
      <div v-if="user_package.status !== 'collected'">
        <p style="color: red">החבילה טרם נאספה</p>
      </div>
      <div v-if="user_package.status == 'waiting'">
          <button @click="open_door(user_package.box_id)">פתח</button>
      </div>
    </div>
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";

export default {
  name: "BackofficeUserPackagesAdmin",
  components: {
    BackofficeBase,
  },
  data() {
    return {
      loader_prop: true,
      view_data: [],
    };
  },
  async mounted() {
    this.phone_number = this.$route.query.phone_number;
    let backendModel = new BackendModel();
    let view_data = await backendModel.backendRequest(
      "/Api/service/backoffice/admin_get_user_packages",
      { phone_number: this.phone_number }
    );
    this.view_data = view_data.data.view_data;
    console.log(this.view_data);
    this.loader_prop = false;
  },

  methods: {

    async open_door(id) {
      this.loader_prop = true;
      let backendModel = new BackendModel();
     let res =  await backendModel.backendRequest(
        "/Api/service/backoffice/admin_open_box",
        { id }
      );
      if(res.data.res == "OK"){
     window.location.reload();
     }

    },
  },
};
</script>

<style scoped>
@import "../../assets/style.css";
</style>

